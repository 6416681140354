import {  useContext, useEffect } from "react";
import { ScrollContext } from "../Contexts/scrollContext";
import { FaCode, FaHome } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { HashLink as Link } from "react-router-hash-link";
import { Roadmap } from './roadmap';
export function Nav()
{

    const {isScrolled, setTrue, setFalse } = useContext( ScrollContext ); 

    useEffect( () => 
    {
        const handleScroll = () => 
        {
            window.scrollY > 0 ? setTrue() : setFalse();
        }; 

        window.addEventListener("scroll", handleScroll );

        return () => 
        {
            window.removeEventListener("scroll", handleScroll );
        };

    }, []);


    return <nav className="bg-[#0e1627] pr-4" >
        
        <div className="px-2">

            <div className="flex justify-center w-full">

                <div className="md:flex items-center float-left space-x-2">
                    
                    <Link to="/" className="py-4 px-2 text-white font-semibold inline-block">
                        <FaHome className="float-left mt-1 mx-2"/>
                        Home
                    </Link>

                    <Link to="/skills" className="inline-block py-4 px-2 text-white font-bold hover:text-purple-500 transition duration-300">
                        <div>
                            <FaCode className="float-left mt-1 mx-2"/> Roadmap
                        </div>
                    </Link>

                    {/* <Link to="/#contact" className=" inline-block py-4 px-2 text-white font-semibold hover:text-purple-500 transition duration-300">
                        <IoCall className="float-left mt-1 mx-2"/>
                        Contacts
                    </Link> */}

                </div>

            </div>

        </div>

    </nav>    
}