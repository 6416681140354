import "./css/skill.css";

export interface skillDetails
{
    index?: number;
    name: string;
    img: string;
    date: string;
    text: string;
    background?: string;
}

export interface skillProps
{
    data: skillDetails;
    click: any;
}

export function Skill( props: skillProps )
{
    
    return (
    <div key={props.data.index} className="w-[100%] px-2 py-4 ml-[2%]" style={{ borderRight: "3px solid"+`${props.data.background}` }}>
            
            <img onClick={ () => props.click( props.data ) }  src={`/assets/${props.data.img}`} alt="logo" width="32" style={{ boxShadow: '0 0 2px '+`${props.data.background}`,border: "3px solid "+`${props.data.background}`}} className='mt-[-3%] w-[32px] rounded-full border-[3px] border-[#7d3cf5] float-right mr-[-25px] hover:w-[34px] hover:mr-[-26px] hover:border-[4px]'/>
            <div  onClick={ () => props.click( props.data ) } className="shadow-md px-3 py-2 mt-2 skillbull bg-[]" style={{ background:`${props.data.background}`, borderRadius:"10px" }}>
                <span className="float-right text-sm bg-white pt-[0.2%] px-2 rounded" style={{ color:`${props.data.background}` }}>
                    { props.data.date }
                </span>    
                <span className="font-bold text-xl text-white"> 
                    { props.data.name } 
                </span>
                
                <p className="my-2 text-white">
                    { props.data.text }
                </p>

                {/* <div className="triangle-right" style={{ borderLeft: "10px solid"+`${props.data.background}`}} ></div> */}
            
            </div>

    </div>);
}