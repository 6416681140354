import { useState } from "react";
import { Aboutme } from "./About";
import { Contact } from "./Contact";
import { Contactform } from './contactForm';

import "./css/home.css";
import "./css/tech.css";


export function Home()
{

    return (

        <>
            <div className=" w-full">
                
                <div className="text-white w-full text-center mt-6">
                    <span className="lg:text-2xl font-bold mx-auto mb-2 ">
                        Hello, I'm Tsilavina
                    </span>
                </div>

            </div>

            <div className=" w-full mt-[5%] mb-4 inline-block">
                <Contact  />
            </div>

            <div className="w-full inline-block"> 
                
                <div className=" w-full lg:w-1/2 mt-3 lg:px-4">
                    <Aboutme className="float-left mx-4 text-white "/>
                </div>
               

                <div className="w-full lg:w-1/2 float-right">
                    <Contactform className="mx-auto mb-3"/>
                </div>
                
            </div>

        </>
     );
}
