import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Nav } from './Components/Nav';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { Home } from './Components/Home';
import { Roadmap } from './Components/roadmap';
import { ScrollContext, LangContext } from './Contexts/scrollContext';
import { Footer } from './Components/Footer';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


function App()
{

  // const router = createBrowserRouter([
  //   {
  //     path:"/",
  //     element: <Home />
  //   },
  //   {
  //     path:"/skills",
  //     element: <Roadmap />
  //   }
  
  // ]);
  
  const [ isScrolled, setIsScrolled ] = useState( false );
  const [ lang, setLang ] = useState( "french" );
  
  const setFalse = ( ) => 
  {
    setIsScrolled( false );
  };
  
  const setTrue = ( ) => 
  {
    setIsScrolled( true );
  };

  const changeLang = ( value: string ) => 
  {
    setLang( value );
  }

  return (
    
    <ScrollContext.Provider value={{ isScrolled, setTrue, setFalse  }}>
      
      <LangContext.Provider value={{ lang, changeLang }}>
        <HashRouter>
          <Nav />
          <Routes>
              <Route path='/' element={ <Home /> } />
              <Route path='/skills' element={ <Roadmap /> } />
          </Routes>
          <Footer />
        </HashRouter>
      </LangContext.Provider>

    </ScrollContext.Provider>
  )
}



root.render(

  <React.StrictMode>
    <App />
  </React.StrictMode>
  
);


reportWebVitals();
