import { useEffect, useRef, useState } from "react";
import './css/contact.css';
import { emailValidator } from "../utils/validators";
import { sendMessage } from '../utils/sendMail';
import ReCAPTCHA from "react-google-recaptcha";

interface Message
{
    sender_name: string;
    sender_email: string;
    message: string;
}

interface LoadingState
{
    loading_text: string;
    loading_class: string;
    load: string
}

interface contactProp
{
    className: string;
}

export function Contactform( prop: contactProp )
{

    const [ message, setMessage ] = useState({
        sender_email:"",
        sender_name:"",
        message:""
    } as Message );

    const [ errormail, setErrormail ] = useState( 0 );
    const [ errorname, setErrorname ] = useState( 0 );
    const [ errormessage, setErrormessage ] = useState( 0 );
    const [ email, setEmail ] = useState( 0 );
    const [ error, setError ] = useState( "" );
    const [ success, setSuccess ] = useState( "" );
    const [ loading, setLoading ] = useState( {
        
        loading_text:"Send",
        loading_class: "",
        load:""

    } as LoadingState );

    const [ reToken, setReToken ] = useState( null );
    const recaptcha = useRef( null );
    const form_ = useRef( null );

    const submit = async ( e: React.FormEvent<HTMLFormElement>) => 
    {

        e.preventDefault();

        if( reToken == "" )
        {
            setSuccess("");
            setError("Invalid reCAPTCHA");
        }

        if( loading.loading_text.toLocaleLowerCase() != "send" )
        {
            return false;
        }

        if( message.sender_name == "" )
        {
            setErrorname( -1 );
            return false;
        }else
        {
            setErrorname( 1 );
        }

        if( message.message == "" )
        {
            setErrormessage( -1 );
            return false;
        }else
        {
            setErrormessage( 1 );
        }


        if( message.sender_email == "" )
        {
            setErrormail( -1 );
            setEmail( 1 );
            return false;
        }

        setLoading( c => ({
            
            loading_text:"Sending",
            loading_class:"animate-ping",
            load:"..."

        } as LoadingState ));

        setError("");
        setSuccess("");

        let sent = await sendMessage( message.sender_name, message.sender_email, message.message, reToken || "" );

        setLoading( c => ({
            
            loading_text:"Send",
            loading_class:"",
            load:""

        } as LoadingState ));

        if( sent.status )
        {
            setSuccess("Thank you for your message. I will respond as soon as possible.");
            setError("");

            setMessage( c => ({
                sender_name:"", 
                sender_email:"",
                message:""} as Message) );

            setEmail( 0 );
            
            (form_.current as any ).reset();
            (recaptcha.current as any ).reset();

        }else
        {

            setSuccess("");
            if( sent.message )
            {    

                setError( sent.message );

            }else
            {
                setError("Something went wrong!");
            }

            (recaptcha.current as any ).reset();

        }

        
    };
    
    const handleCaptcha = async ( response: any ) => 
    {
        setReToken(response);
    };

    const inputName = async ( e: React.ChangeEvent<HTMLInputElement> ) =>
    {        
        setMessage( c => ({
            
            sender_name:e.target.value, 
            sender_email:c.sender_email,
            message:c.message } as Message) );
        
    };

    const inputEmail = async ( e: React.ChangeEvent<HTMLInputElement> ) =>
    {        
        setMessage( c => ({
            sender_name:c.sender_name, 
            sender_email:e.target.value,
            message:c.message} as Message) );
        
        setEmail( c => ( c + 1 ) );
    };

    const inputMessage = async ( e: React.ChangeEvent<HTMLTextAreaElement> ) =>
    {        
        setMessage( c => ({
            sender_name:c.sender_name, 
            sender_email:c.sender_email,
            message:e.target.value} as Message) );
        
    };

    useEffect(()=>
    {
        if( emailValidator( message.sender_email ) )
        {
            console.log("valid");
            setErrormail( 1 );
        }else
        {
            console.log("invalid");
            setErrormail( -1 );
        }


        if( message.sender_name.length > 2 )
        {
            setErrorname( 1 );
        }

        if( message.message.length > 0  )
        {
            setErrormessage( 1 );
        }

    }, [message]);

    
    return (
        
        <div className=" justify-center flex ">
            
            <form onSubmit={submit} className={`${prop.className} lg:w-[70%] w-[95%]`} ref={form_}>

                <div className="mt-2 text-white">
                    <span className="text-[#ff7050] font-bold text-xl"> Contactez-moi </span>
                </div>

                <div className={`flex items-center text-[#fa5757] border-b-2 border-[#fa5757] text-center text-sm font-bold px-4 py-3 ${ error == "" ? "hidden":"" } `} role="alert">
                    <p className="text-center"> { error } </p>
                </div>

                <div className={`flex items-center text-[#57faae] border-b-2 border-[#57faae] text-center text-sm font-bold px-4 py-3 ${ success == "" ? "hidden":"" } `} role="alert">
                    <p className="text-center"> { success } </p>
                </div>

                <div className="mt-2">
                    <label htmlFor="Name" className="font-bold mb-4 text-white"> Votre Nom </label>
                    <div>
                        <input placeholder="Nom" name="Name" type="text" onChange={ inputName }
                        className={`bg-[#283652] text-white focus:outline-none mt-3 rounded-lg focus:ring-blue-500 focus:border-blue-500 text-gray block w-full p-2 ${ ( errorname == -1 ) ? "invalid-email":"" }`} />
                    </div>
                </div>

                <div className="mt-4">
                    
                    <label htmlFor="email" className="font-bold mb-4 text-white"> Votre Email </label>
                    
                    <div>
                        <input placeholder="email" name="email" type="email" onChange={ inputEmail }
                        className={`bg-[#283652] text-white mt-3 focus:border-0 border-0 focus:outline-none rounded-lg block w-full p-2 ${ (errormail == -1 && email > 0 ) ? "invalid-email":"" }`}
                        />
                    </div>

                </div>


                <div className="mt-4">
                                        
                    <label htmlFor="message" className="font-bold mb-4 text-white" > Votre Message </label>
                    <textarea id="message" onChange={ inputMessage } rows={4} className={`bg-[#283652] text-white mt-3 focus:outline-none block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500 ${ ( errormessage == -1 ) ? "invalid-email":"" } `} placeholder="Write your thoughts here..."></textarea>
                </div>

                <div className=" flex justify-center">
                    
                    <ReCAPTCHA
                        ref={recaptcha}
                        className="mt-3"
                        sitekey="6Lcdky0qAAAAACKR8_PPyp_6NyW5PRL8-OXgVN2J"
                        onChange={handleCaptcha}
                    />

                </div>
                

                {/* bg-[#ff7050] */}
                <div className="mt-2 text-center text-white">
                    <button type="submit" className="w-full font-bold text-primary_orange bg-[#283652] focus:outline-none hover:bg-primary_orange hover:text-white font-medium rounded-lg text-sm px-6 py-2.5 mb-2"> 
                        {loading.loading_text}<span className={" font-semibold " + loading.loading_class }>{loading.load}</span>
                    </button>
                </div>
            
            </form>

        </div>
        
    );
}