
export function Footer()
{
    
    return (
        <div className="w-full flex pb-1 justify-center mt-3 text-white text-xs">
            
            Made with

            <span className="text-xs text-[#1c96c7] ml-2">
                React/TailwindCss
            </span>

        </div>
    )
}