import axios from "axios";
import { CONSTANTS } from "../Constantes";


export const sendMessage = async ( name: string, email: string, message: string, gtoken: string ) => 
{
    const encoded = new URLSearchParams();
    encoded.append("name", name );
    encoded.append("email", email );
    encoded.append("message", message );
    encoded.append("recaptcha", gtoken );

    try
    {

        let sending = await axios.post( `${CONSTANTS.api_url}/receivemessage.php`, encoded,
        {
            headers:{
                "Content-Type":"application/x-www-form-urlencoded"
            }
        });

        return sending.data;

    }catch(e)
    {
        console.log( e );
        return {status:false, message:"Something went wrong."};
    }

};