
import { useState, useContext, useEffect } from 'react';
import { colors } from './color';
import "./css/roadmap.css";
import { Skill, skillDetails } from "./skill";
import { ScrollContext } from '../Contexts/scrollContext';
import { loadSkill } from '../utils/loadSkills';


export function Roadmap()
{
    
    const { isScrolled, setTrue, setFalse } = useContext( ScrollContext );
    const [ skills, setSkills ] = useState<skillDetails[]>([]);
    const [selected, setSelected ] = useState<skillDetails>({
            index:0,
            name:"Loading...",
            img:"loading.png",
            date:"-- --",
            text:"Loading...",
            background: "#515151"
    });


    useEffect( () => 
    {

        const loading = async () => 
        {
            let res = await loadSkill() || [];
            let i = 0;
            
            for( let j=0; j < res.length;j++ )
            {
                let sk = res[j];

                if( sk.background && sk.background?.trim().length > 0 )
                {
                    i++;
                    if( i == colors.length )
                    { 
                        i = 0;
                    }
                    continue;
                }

                sk.background = colors[i];

                i++;
                if( i == colors.length )
                { 
                    i = 0;
                }
            }

            setSelected( res[0] );
            setSkills( res );
        };

        loading();

    }, []);

    
    const clickHandler = ( data: skillDetails ) => 
    {
        setSelected( data );
    };


    return (
        <>
            <div className="bg-[#0f172a]  mt-[2%] mx-auto w-[80%] sm:w-[45%] float-left ml-[3%] ">
                {
                    skills.map( ( sk, index ) => (
                        <Skill data={ sk } key={index} click={ clickHandler } />
                    ))
                }
            </div>

            <div className={` hidden sm:block w-[45%] mr-[3%] mb-[3%] mt-[2%] h-screen pl-[2%] pt-3 pr-3 fixed float-right shadow bg-[#121c33] ${ isScrolled ? 'increase': 'normal' }`} style={{ right:"0px", borderRadius: "5px" }} >
                <div>
                    <h2 className='text-white inline-block px-2 mt-3' style={{ borderRadius: "4px",fontWeight:"bold", background:`${selected.background}` }} >
                        <span>
                          { selected.name }
                        </span>
                    </h2>
                </div>
                

                <p className="text-white ml-[1%] mt-3">
                    { selected.text }
                </p>

            </div>
        </>
    );

}