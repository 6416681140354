import { useContext } from "react";
import { LangContext } from "../Contexts/scrollContext";

interface AboutProps
{
    className: string;
}

export function Aboutme( props: AboutProps )
{
    const { lang, changeLang } = useContext( LangContext );

    const eng = (
        <div className={props.className}>
            <p className="lg:text-lg">
                <div className="text-md">
                    Developer in creating websites and desktop applications using technologies such as
                    <span className="font-semibold tech">HTML</span>,
                    <span className="font-semibold tech">CSS</span>,
                    <span className="font-semibold tech">JavaScript</span>,
                    <span className="font-semibold tech">Angular</span>,
                    and exploring
                    <span className="font-semibold tech mx-1">React</span>.
                    On the back-end, I use
                    <span className="font-semibold tech">ExpressJS</span> with <span className="font-semibold tech">TypeScript</span>,
                    <span className="font-semibold tech">NodeJS</span>, and am proficient in
                    <span className="font-semibold tech">PHP/Symfony</span>.
                    I manage databases with
                    <span className="font-semibold tech">SQLite</span> and
                    <span className="font-semibold tech">SQL</span>,
                    integrating them into secure systems.
                    Additionally, I develop cross-platform desktop applications with
                    <span className="font-semibold tech">Electron</span>.
                    While gaining experience in web pentesting,
                    I actively apply security practices for resilient applications.
                    I prefer <span className="font-semibold tech">Linux</span> for development,
                    use <span className="font-semibold tech">Git</span> for version control, and Docker for efficient deployments.
                    I am currently a student at
                    <span className="font-semibold tech"> CNTEMAD </span>
                    Antananarivo.
                </div>
                <span className="text-white inline-block mt-4">
                    Feel free to contact me if you need my skills or services.
                </span>
            </p>
        </div>
    );

    const fr =  (

        <div className={props.className}>
            <p className="lg:text-lg">
                <div className=" text-md">
                    Développeur dans la création des sites web et des applications 
                    desktop en utilisant des technologies telles que
                    <span className=" font-semibold tech">HTML</span>,
                    <span className=" font-semibold tech">CSS</span>,
                    <span className=" font-semibold tech">Javascript</span>,
                    <span className=" font-semibold tech">Angular</span>,
                    et en explorant
                    <span className=" font-semibold tech mx-1"> React </span>.
                    Du côté back-end, j'utilise 
                    <span className=" font-semibold tech"> ExpressJS
                    </span> avec <span className=" font-semibold tech">TypeScript</span>,
                    <span className=" font-semibold tech"> NodeJS </span>, et maîtrise
                    <span className=" font-semibold tech"> PHP/Symfony/Laravel </span>.
                    Je gère les bases de données avec 
                    <span className=" font-semibold tech">SQLite</span>
                    et 
                    <span className=" font-semibold tech">SQL</span>,
                    les intégrant dans des systèmes sécurisés. 
                    De plus, je développe des applications de bureau multi-plateformes avec
                    <span className=" font-semibold tech">Electron</span>.
                    Tout en acquérant de l'expérience dans web pentesting,
                    j'applique activement des pratiques de sécurité pour des applications résistantes.
                    Je préfère <span className=" font-semibold tech">Linux</span> pour le développement, 
                    j'utilise <span className=" font-semibold tech">Git</span> pour le contrôle de version et Docker pour des déploiements efficaces.
                    Je suis actuellement étudiant à 
                    <span className=" font-semibold tech">CNTEMAD</span>
                    Antananarivo.
                
                </div>
                <span className="text-white inline-block mt-4">
                    N'hésitez pas à me contacter si vous avez besoin de mes compétences ou de mes services.
                </span>
            </p>
        </div>
    );

    if( lang == "french" )
    {
        return fr;
    }else
    {
        return eng;
    }
}