import { createContext } from "react";

export const ScrollContext = createContext({
    isScrolled: false,
    setTrue: () => {},
    setFalse: () => {}
});

export const LangContext = createContext({
    lang: "french",
    changeLang: ( value: string ) => {}
});

// const ScrollProvider = ({ children }) => 
// {
    
//     const [ isScrolled, setIsScrolled ] = useState(false);

//     useEffect( () => 
//     {
//         const handleScroll = () => 
//         {
//             setIsScrolled( window.scrollY > 0 )
//         }; 

//         window.addEventListener("scroll", handleScroll );

//         return () => 
//         {
//             window.removeEventListener("scroll", handleScroll );
//         };

//     }, []);

//     return(
//         <ScrollContext.Provider value={{ isScrolled }}>
//             {children}
//         </ScrollContext.Provider>
//     );

// };