import axios from "axios";
import { CONSTANTS } from "../Constantes";
import { skillDetails } from "../Components/skill";

export const loadSkill = async () => 
{
    
    try 
    {
        let data = await axios.get( CONSTANTS.skills_json );
        const sk: skillDetails[] = data.data;

        return sk;    

    } catch (error) 
    {

        return [{
            index:0,
            name:"Failed to load the skill list",
            img:"error.png",
            date:"-- --",
            text:"impossible to retrieve the skills data",
            background: "#F52D3F"}]; 
    }
    
};